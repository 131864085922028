import {map} from 'rxjs/operators';
import {Item} from './../../../shared/modal/inventory/item/item';
import {Observable, of} from 'rxjs';
import {BaseService} from './../../../shared/service/base.service';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OrdersCreationService {

  // keep order data like desc , delivry method and other values saved
  private _orderData: any = {};
  // save session data
  private sessionData = null;
  private selectedPosItems = JSON.parse(localStorage.getItem('SelectedPosItems'));

  // save selected items
  private SelectedItems: Item[] = [];
  // backup items
  private _items: Item[] = [];

  constructor(private _base: BaseService) {
  }

  get orderData() {
    return this._orderData;
  }

  set orderData(value) {
    this._orderData = value;
  }

  get items() {
    return this._items;
  }

  set items(items) {
    this._items = items;
  }

  getSelectedItem() {
    return this.SelectedItems;
  }

  setSelectedItem(SelectedItem: Item[]) {
    this.SelectedItems = SelectedItem;
  }

  getSessionData() {
    return this.sessionData;
  }

  setSessionData(sessionData: any) {
    this.sessionData = sessionData;
  }

  /**
   * get categories
   */
  getCategories(): Observable<any> {
    return this._base.get('api/v1/category');
  }

  getCategoriesByBranch(branchId): Observable<any> {
    return this._base.get(`api/v1/category/findByBranch?branchId=${branchId}`);
  }

  /**
   * get last session by user
   */
  getLastSessionByUser(): Observable<any> {
    return this._base.get(`api/v1/pos-session/last-session-user`);
  }

  /**
   *
   * @param id
   */
  getSubcategoryByCatId(id: number): Observable<any> {
    return this._base.get('api/v1/subcategory/findByCategoryId?categoryId=${id}');
  }

  /**
   * get items by branchid or category id or subcategory id
   * @param branchId
   * @param catId
   * @param subcatId
   */
  getItems(
    branchId: number,
    catId?: number,
    subcatId?: number
  ): Observable<any> {
    const posItems = localStorage.getItem('PosItems');
    if (posItems) {
      this.items = JSON.parse(posItems);
      return of(this.items);
    }
    return this._base.get(
      `api/v1/branch-items/pos/${branchId}?${catId ? '&catId=' + catId : ''
      }${subcatId ? '&subCatId=' + subcatId : ''}`
    ).pipe(map(res => {
      this.items = res?.data ? res.data : [];
      localStorage.setItem('PosItems', JSON.stringify(res.data));
      return res.data;
    }));
  }

  /**
   * get branch items
   * @param branchId
   * @param itemId
   */
  getBranchItem(branchId: number, itemId: number): Observable<any> {
    return this._base.get(`api/v1/branch-items/findByBranchIdAndItemId?branchId=${branchId}&itemId=${itemId}`);
  }

  /**
   * get units of measure
   */
  getUnits(): Observable<any> {
    return this._base.get('api/v1/unit');
  }

  /**
   * @param itemId
   */
  getItemAttachmentsById(itemId: number): Observable<any> {
    return this._base.get(`api/v1/item-attach/findByItemId?itemId=${itemId}`);
  }

  /**
   * end session
   */
  endSession(session: any): Observable<any> {
    return this._base.put(session, 'api/v1/pos-session');
  }

  public updateFromAndroid(message) {
    console.log('service message is >> ', message);
    //  this.searchValue  = message;
    alert('service message is >> ' + message);
  }

  public findItemColors(mainBranchItemId): Observable<any> {
    return this._base.get(`api/v1/item-rev-values/item/color?mainBranchItemId=${mainBranchItemId}`);
  }
  public findItemSizes(mainBranchItemId,colorId): Observable<any> {
    return this._base.get(`api/v1/branch-items/size?mainBranchItemId=${mainBranchItemId}&colorId=${colorId}`);
  }
  public approvalRequest(obj:any):Observable<any>{
    return  this._base.post(obj,'api/v1/approval-request')
  }

   openedOrdersCount(posSessionId): Observable<any> {
    return this._base.get(`api/v1/issuing-order/openedOrders/${posSessionId}`);
  }
}
