import {TranslateService} from '@ngx-translate/core';
import {BaseService} from './../../service/base.service';
import {Component, EventEmitter, Input, OnDestroy, Output,} from '@angular/core';
import {SnackService} from '../snack/snack.service';
import {Constant} from '../../constant/constant';

@Component({
  selector: 'upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss'],
})
export class UploadFilesComponent implements OnDestroy {
  constructor(
    private _base: BaseService,
    private snack: SnackService,
    private constants : Constant,
    public _translate: TranslateService
  ) {
  }

  uploadComponentId = 'UC' + Math.floor(Math.random() * 100);
  static uploadedFiles: Object[] = [];
  reader = new FileReader()
  validImage:boolean = true;

  //--------------------------------

  @Input()
  componentId: string;
  @Input()
  multiple_upload: boolean = false;
  @Output()
  uploadInfo = new EventEmitter<any>();
  @Output()
  imagesUrlsEvent = new EventEmitter<string>();

  async onSelectFileChange(event: any)  {
    const files: FileList = event.target.files;
    if (!this.validateFile(files[0].name)) {
      this.snack.showSnack('COMMON.NOT_SUPPORTED', 'error');
      return false;
    }
    const isValid = await  this.validateWidthAndHeight(files);
    this.validImage = isValid;
    if (this.validImage){
      if (files.length > 0) {
        event.target.nextElementSibling.innerHTML = `${files.length} ${this._translate.instant('COMMON.SELECTED_FILES_NUMBER_IS')}`;
        if (UploadFilesComponent.uploadedFiles?.length > 0) {
          const foundedItem = UploadFilesComponent.uploadedFiles.find(
            (item) => item['pathId'] == this.componentId
          );
          // console.log(foundedItem);
          if (foundedItem) {
            foundedItem['files'] = files;
          } else {
            UploadFilesComponent.uploadedFiles.push({
              pathId: this.componentId,
              files: files,
            });
          }
        } else {
          UploadFilesComponent.uploadedFiles.push({
            pathId: this.componentId,
            files: files,
          });
        }
        //-------------------------------------------------
        if (UploadFilesComponent.uploadedFiles?.length > 0) {
          this.uploadInfo.emit(UploadFilesComponent.uploadedFiles);
        }
        // console.log(UploadFilesComponent.uploadedFiles);
      }
    }

  }

  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'png' || ext.toLowerCase() == 'jpeg' || ext.toLowerCase() == 'jpeg'|| ext.toLowerCase() == 'jpg') {
      return true;
    } else {
      return false;
    }
  }

  uploadData(uploadedObject: any) {
    // console.log(uploadedObject);
    let uploadedDataa = [];
    const pathId = uploadedObject.pathId;
    if (UploadFilesComponent.uploadedFiles.length > 0) {
      const returnedItem = UploadFilesComponent.uploadedFiles.find(
        (item) => item['pathId'] == pathId
      );
      if (returnedItem) {
        const returnedFileList = returnedItem['files'];
        if (returnedFileList.length > 0) {
          // console.log('here is returnedFileList : ', returnedFileList);
          let files = Array.from(returnedFileList);
          for (let index = 0; index < files.length; index++) {
            // console.log(
            //   'here my upload item :',
            //   uploadedObject.uploadData[index]
            // );
            uploadedDataa.push({
              id: uploadedObject.uploadData[index].id,
              pathId: pathId,
              file: files[index],
            });
          }
        }
        // console.log(uploadedDataa);
        for (const uploadItem of uploadedDataa) {
          this._base
            .uploadToGoogle(
              uploadItem['id'],
              uploadItem['pathId'],
              uploadItem['file']
            )
            .subscribe(
              (res) => {
                // console.log('File Upload Res', res, res.data.fileDownloadUri);
                this.imagesUrlsEvent.emit(res.data.fileDownloadUri);
              },
              (err) => console.log(err),
              () => {
                console.log('file Upload Complete');
              }
            );
        }
        //remove uploaded items
        UploadFilesComponent.uploadedFiles = UploadFilesComponent.uploadedFiles.filter(
          (item) => item['pathId'] != pathId
        );
        // console.log(UploadFilesComponent.uploadedFiles);
      } else {
        // console.log('Files Already Uploaded ------- ');
      }
    }
  }

  openFileInput(event: any) {
    const nextSibling = event.target.nextElementSibling;

    // Check if the next sibling exists and is a file input
    if (nextSibling && nextSibling.tagName === 'INPUT' && nextSibling.type === 'file') {
      nextSibling.click();
    } else {
      console.error('No next sibling element found or it is not a file input.');
    }
  }


  readImage(reader): Promise<any> {
    const promise = new Promise((resolve, reject) => {
      reader.addEventListener('load', function(e) {
        let image : HTMLImageElement = new Image();
        if (typeof e.target.result === 'string') {
         image.src = e.target.result;
        }
        resolve(image);
      }, false);
      reader.addEventListener('error', function(event) {
        reject(event);
      }, false);
    });
    return promise;
  }




 async validateWidthAndHeight(files) {
    let snack = this.snack
    this.reader.readAsDataURL(files[0]);
    if (this.componentId === this.constants.IMAGE_ECOMMERCE_CONFIG_AD_WEB) {
      let snack = this.snack
      const image = await  this.readImage(this.reader);
      // console.log("image",image);
      const obj = await  this.loadImage(image);
      if (obj.height > 360 || obj.width > 1370) {
        snack.showSnack("Height must not exceed 360px and Width must not exceed 1370px.","error");
        return false;
      }else {
        return true;
      }
    }
   if (this.componentId === this.constants.IMAGE_ECOMMERCE_CONFIG_AD_MOBILE) {
     let snack = this.snack
     const image = await  this.readImage(this.reader);
     // console.log("image",image);
     const obj = await  this.loadImage(image);
     if (obj.height > 100 || obj.width > 250) {
       snack.showSnack("Height must not exceed 100px and Width must not exceed 250px.","error");
       return false;
     }else {
       return true;
     }
   }
  }

  loadImage(image): Promise<any> {
    const promise = new Promise((resolve, reject) => {
      image.addEventListener('load', function() {
        let height = image.naturalHeight;
        let width = image.naturalWidth;
        resolve({width:width,height:height});
      }, false);
      image.addEventListener('error', function(event) {
        reject(event);
      }, false);
    });
    return promise;
  }
  imageLoad(snack,image){
    image.onload = function () {

    }
  }

  ngOnDestroy(): void {
    // console.log('Start Destroied Upload Files Component ... ');
    UploadFilesComponent.uploadedFiles.length = 0;
  }
}
